import React from "react"
import { graphql } from "gatsby"
import HeroPost from "../components/HeroPost"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import Layout from "../components/Layout"
import styled from "styled-components"
import MoreStories from "../components/blog/MoreStories"
import Seo from "../components/seo"
import Header from "../components/blog/Header"

const blog = ({ data: { allPosts } }) => {
  const heroPost = allPosts.nodes.slice(0, 3);
  const morePosts = allPosts.nodes.slice(3)

  return (
    <CustomThemeProvider>
      <Layout>
        <Seo title={"Robinize Blog"} />
        <Background>
          <MainWrapper>
            <Header title={"Robinize Blog"}/>
            <SubTitle>The latest news, interviews, technologies and resources</SubTitle>
            {heroPost && (
              heroPost?.map((post,index) => (
                <HeroPost
                key={post.title}
                title={post.title}
                coverImage={post.coverImage}
                date={post.date}
                author={post.author}
                slug={post.slug}
                excerpt={post.excerpt}
              />
              ))
            )}
            {morePosts.length > 0 && <MoreStories posts={morePosts} />}
          </MainWrapper>
        </Background>
      </Layout>
    </CustomThemeProvider>
  )
}

export default blog

const Background = styled.div`
  background:${({theme}) => theme.colors.background};
  width:100%;
  height:100%;
`

const SubTitle = styled.p`
  font-size:1.2rem;
  color:${({theme}) => theme.colors.gray};
  margin-top:0;
  margin-bottom:5rem;
`

const MainWrapper = styled.div`
  padding: 5rem 0px 2rem 0px;
  max-width:1280px;
  margin: 0 auto;

  @media (max-width:1334px) {
    padding-left:10%;
    padding-right:10%;
  }
`


export const query = graphql`
  {
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          large: gatsbyImageData(width: 720 height: 400)
          small: gatsbyImageData(width: 500 height: 270)
        }
        author {
          name
          picture {
            gatsbyImageData(
              layout: FIXED
              width: 48
              height: 48
            )
          }
        }
      }
    }
  }
`
