import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Header = ({ title }) => {
  return (
    <HomeTitle>
      <Link to="/blog">{title}</Link>
    </HomeTitle>
  )
}

export default Header

const HomeTitle = styled.h1`
  font-size: clamp(1.5rem,3vw,3rem);
  font-family: InterBold;
  position: relative;
  display:inline-block;
  margin-bottom:0.5rem;

  & > * {
    color: ${({ theme }) => theme.colors.fontBlack};
    transition: all 0.3s ease-out;
    text-decoration: none;
  }

  &:after {
    content: "";
    bottom:-7px;
    left:0;
    height: 2px;
    width: 0%;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    transition: width 0.2s ease-out;
  }

  &:hover {
    &:after {
      width:100%;
    }
  }
`
