import React from "react"
import Avatar from "./blog/Avatar"
import CoverImage from "./blog/CoverImage"
import styled from "styled-components"
import { Link } from "gatsby"
import { Excerpt } from "./blog/PostPreview"
import {Helmet} from 'react-helmet';

const HeroPost = ({ title, coverImage, date, excerpt, author, slug }) => {
  return (
    <>
      <Helmet>
        {title}
      </Helmet>
    <MainWrapper>
      <ImageWrapper>
        <CoverImage title={title} fluid={coverImage.large} slug={slug} />
      </ImageWrapper>
      <Content>
        <LinkDate>
          <Title>
            <Link to={`/blog/${slug}`}>{title}</Link>
          </Title>
        </LinkDate>
        <ExcerptAvatar>
          <Excerpt>{excerpt}</Excerpt>
          <Avatar
            name={author?.name}
            picture={author?.picture}
            dateString={date}
          />
        </ExcerptAvatar>
      </Content>
    </MainWrapper>
    </>
  )
}

export default HeroPost

const MainWrapper = styled.section`
  margin-bottom: 4rem;
  display: flex;
  gap: 2rem;
  flex-direction:column;

  @media (min-width: 924px) {
    flex-direction:row;
  }
`

const ImageWrapper = styled.div`
  max-width: 710px;
  max-height: 390px;
  height: 100%;
  width: 100%;

  @media (min-width: 924px) {
    width:60%;
  }
`

const ExcerptAvatar = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: 642px) {
    align-items: flex-start;
    text-align: left;
    padding-left: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  width:100%;

  @media (min-width: 924px){
    width:40%;
  }

  @media (max-width: 642px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const LinkDate = styled.div``

const Title = styled.h3`
  & > * {
    text-decoration: none;
    font-size: clamp(1.3rem, 2vw, 2rem);
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.colors.fontBlack};

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`
